import { Injectable } from '@angular/core';
import { SessionData } from '../../domain/session-data';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  private _sessionData: SessionData;

  constructor(private cookieService: CookieService) {}

  public setSessionData(value: SessionData) {
    this._sessionData = value;
    sessionStorage.setItem('sessionData', JSON.stringify(value));
    return of(this._sessionData);
  }

  public setDeviceId(value: string): void {
    if (value !== undefined) {
      localStorage.setItem('d718cbf23cc6fcf3585d937dc636a62915654f3b', value);
    }
  }

  public getDeviceId(): string {
    let deviceId = this.cookieService.get('d718cbf23cc6fcf3585d937dc636a62915654f3b');

    if (!deviceId) {
      deviceId = localStorage.getItem('d718cbf23cc6fcf3585d937dc636a62915654f3b');
    }

    if (deviceId === 'undefined') {
      deviceId = undefined;
    }

    return deviceId;
  }

  getSessionData(): Observable<SessionData> {
    if (this._sessionData) {
      return of(this._sessionData);
    } else {
      return of(JSON.parse(sessionStorage.getItem('sessionData')));
    }
  }

  getSessionDataObject(): SessionData {
    if (this._sessionData) {
      return this._sessionData;
    } else {
      return JSON.parse(sessionStorage.getItem('sessionData'));
    }
  }
}
